<!-- src/components/TldInfo.vue -->

<template>
  <div>
    <div v-if="!isLoading && tldCount" class="tld-info">
      <h2>Tlds Found: <span>{{ tldCount.registered_count }}</span></h2>
      <div class="tld-list-box">
        <div v-for="(tld, index) in sortedTlds" :key="index" class="domain-item">
          <div class="domain-info">
            <div class="name-with-year">
              <a
                  :href="getDomainHomepage(`${domain.split('.')[0]}.${tld}`)"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="domain-link"
              >
                <span class="domain-name">{{ domain.split('.')[0] }}.{{ tld }}</span>
              </a>
            </div>
          </div>
          <a
              :href="`/whois/${domain.split('.')[0]}.${tld}`"
              class="domain-status-link"
          >
            <span class="domain-status registered">Whois</span>
          </a>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading" class="tld-info loading">
      <el-skeleton :rows="2" animated />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, onUnmounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { ElSkeleton } from 'element-plus'
import apiClient from '@/services/api'

// Cache utilities
const CACHE_DURATION = 60 * 60 * 1000

const getCacheKey = (prefix) => `tld_info_cache_${prefix}`

const getFromCache = (prefix) => {
  const key = getCacheKey(prefix)
  const cached = localStorage.getItem(key)
  if (cached) {
    const { data, timestamp } = JSON.parse(cached)
    if (Date.now() - timestamp < CACHE_DURATION) {
      return data
    }
    localStorage.removeItem(key) // Remove expired cache
  }
  return null
}

const setToCache = (prefix, data) => {
  const key = getCacheKey(prefix)
  localStorage.setItem(key, JSON.stringify({
    data,
    timestamp: Date.now()
  }))
}

const getDomainHomepage = (domain) => {
  return `http://${domain}`
}

const props = defineProps({
  domain: {
    type: String,
    default: ''
  }
})

const route = useRoute()

const tldCount = ref(null)
const isLoading = ref(false)
const registeredTlds = ref([])

// Sort TLDs alphabetically
const sortedTlds = computed(() => {
  return [...registeredTlds.value].sort((a, b) => a.localeCompare(b))
})

const clearTldData = () => {
  tldCount.value = null
  registeredTlds.value = []
  isLoading.value = false
}

const fetchTldCount = async (prefix) => {
  const cached = getFromCache(prefix)
  if (cached) {
    return cached
  }

  try {
    const response = await apiClient.get('/api/tlds/count', {
      params: { domain: prefix }
    })
    if (response.data.status === 'success') {
      setToCache(prefix, response.data)
      return response.data
    } else {
      console.error('Failed to fetch TLD count:', response.data.error)
      return null
    }
  } catch (error) {
    console.error(`Error fetching TLD count for ${prefix}:`, error)
    return null
  }
}

const updateTldInfo = async (domain) => {
  if (!domain) {
    clearTldData()
    return
  }

  isLoading.value = true

  try {
    const domainPrefix = domain.split('.')[0]
    const result = await fetchTldCount(domainPrefix)

    if (result) {
      tldCount.value = result
      registeredTlds.value = result.registered_tlds
    }
  } catch (error) {
    console.error('Error updating TLD info:', error)
  } finally {
    isLoading.value = false
  }
}

// Watch for route changes
watch(() => route.path, (newPath) => {
  if (newPath === '/') {
    clearTldData()
  }
})

// Watch for domain changes
watch(() => props.domain, (newDomain) => {
  if (newDomain) {
    updateTldInfo(newDomain)
  } else {
    clearTldData()
  }
}, { immediate: true })

// Clean up when component is unmounted
onUnmounted(() => {
  clearTldData()
})
</script>

<style scoped>
.tld-info {
  margin-bottom: 20px;
}

.tld-info h2 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #2c3e50;
}

.tld-list-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  max-height: 300px;
  overflow-y: auto;
}

.domain-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.domain-info {
  display: flex;
  flex-direction: column;
}

.name-with-year {
  display: flex;
  align-items: center;
  gap: 8px;
}

.domain-name {
  color: #2c3e50;
  font-family: monospace;
}

.domain-link {
  text-decoration: none;
  color: inherit;
}

.domain-link:hover {
  color: inherit;
  text-decoration: none;
}

.domain-status-link {
  text-decoration: none;
}

.domain-status {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.domain-status.registered {
  background-color: #f56c6c;
  color: white;
}

.loading {
  padding: 10px;
}

/* Custom scrollbar styles */
.tld-list-box::-webkit-scrollbar {
  width: 6px;
}

.tld-list-box::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.tld-list-box::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.tld-list-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tld-info h2 span {
  color: #1e850d;
  font-weight: bold;
}
</style>