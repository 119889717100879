<!-- ComTemplate.vue -->
<template>
  <div class="whois-result">
    <div v-if="isDomainAvailable" class="domain-available">
      <el-alert
          title="Domain is available for registration!"
          type="success"
          :closable="false"
          show-icon
      >
        <template #description>
          This domain name is not registered and may be available for purchase.
        </template>
      </el-alert>
    </div>

    <div v-else class="domain-info">
      <div class="info-item">
        <span class="label">Domain:</span>
        <span class="value">{{ formattedData.domain }}</span>
      </div>
      <div class="info-item">
        <span class="label">Registry Domain ID:</span>
        <span class="value">{{ formattedData.registryDomainId }}</span>
      </div>
      <div class="info-item">
        <span class="label">Registrar WHOIS Server:</span>
        <span class="value">{{ formattedData.registrarWhoisServer }}</span>
      </div>
      <div class="info-item">
        <span class="label">Registrar URL:</span>
        <span class="value">{{ formattedData.registrarUrl }}</span>
      </div>
      <div class="info-item">
        <span class="label">Updated Date:</span>
        <span class="value">{{ formattedData.lastUpdated }}</span>
      </div>
      <div class="info-item">
        <span class="label">Creation Date:</span>
        <span class="value">{{ formattedData.creationDate }}</span>
      </div>
      <div class="info-item">
        <span class="label">Expiration Date:</span>
        <span class="value">{{ formattedData.expirationDate }}</span>
      </div>
      <div class="info-item">
        <span class="label">Registrar:</span>
        <span class="value">{{ formattedData.registrar }}</span>
      </div>
      <div class="info-item">
        <span class="label">Registrar IANA ID:</span>
        <span class="value">{{ formattedData.registrarIanaId }}</span>
      </div>
      <div class="info-item status-section" v-if="formattedData.domainStatus.length">
        <span class="label">Domain Status:</span>
        <div class="status-list">
          <div v-for="status in formattedData.domainStatus" :key="status.code" class="status-item">
            <a :href="status.url" target="_blank" rel="noopener noreferrer">{{ status.code }}</a>
          </div>
        </div>
      </div>
      <div class="info-item" v-if="formattedData.nameServers.length">
        <span class="label">Name Servers:</span>
        <div class="nameservers-list">
          <div v-for="ns in formattedData.nameServers" :key="ns">{{ ns }}</div>
        </div>
      </div>
      <div class="info-item">
        <span class="label">DNSSEC:</span>
        <span class="value">{{ formattedData.dnssec }}</span>
      </div>

      <div class="more-info-section">
        <button
            class="more-info-button"
            @click="toggleDetailedInfo"
            :disabled="isLoading"
        >
          <span class="button-content">
            {{ isLoading ? 'Loading...' : 'More Information' }}
            <span class="arrow" :class="{ 'arrow-down': !showDetailedInfo, 'arrow-up': showDetailedInfo }"></span>
          </span>
        </button>

        <div v-if="showDetailedInfo && detailedInfo" class="detailed-info">
          <pre>{{ detailedInfo }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import apiClient from '@/services/api'

export default {
  name: 'ComTemplate',
  props: {
    whoisData: {
      type: Object,
      required: true
    },
    domain: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const savedDomain = ref('')
    const detailedInfo = ref(null)
    const isLoading = ref(false)
    const showDetailedInfo = ref(false)

    const isDomainAvailable = computed(() => {
      return props.whoisData.available === true ||
          (props.whoisData.status && props.whoisData.status.toLowerCase() === 'available')
    })

    watch(() => props.whoisData, (newData) => {
      if (newData && Object.keys(newData).length > 0) {
        savedDomain.value = props.domain
      }
    }, { immediate: true })

    const formatDate = (dateStr) => {
      if (!dateStr) return 'Unknown'
      try {
        const date = new Date(dateStr)
        return date.toISOString().replace('T', ' ').replace(/\.\d{3}Z$/, ' UTC')
      } catch {
        return dateStr
      }
    }

    const extractDomainStatuses = (text) => {
      const statusRegex = /Domain Status:\s*(\w+)\s+(https?:\/\/[^\s]+)/gi
      const matches = [...text.matchAll(statusRegex)]
      const uniqueStatuses = new Map(
          matches.map(match => [match[1], { code: match[1], url: match[2] }])
      )
      return Array.from(uniqueStatuses.values())
    }

    const extractNameServers = (text) => {
      const nsRegex = /Name Server:\s*([^\r\n]+)/gi
      const matches = [...text.matchAll(nsRegex)]
      return [...new Set(matches.map(match => match[1].toLowerCase().trim()))]
    }

    const formattedData = computed(() => {
      if (isDomainAvailable.value) {
        return {}
      }

      const rawText = props.whoisData?.raw_data?.text || ''

      const getValue = (field) => {
        const regex = new RegExp(`${field}:\\s*([^\r\n]+)`, 'i')
        const match = rawText.match(regex)
        return match ? match[1].trim() : 'Unknown'
      }

      return {
        domain: savedDomain.value,
        registryDomainId: getValue('Registry Domain ID'),
        registrarWhoisServer: getValue('Registrar WHOIS Server'),
        registrarUrl: getValue('Registrar URL'),
        lastUpdated: formatDate(getValue('Updated Date')),
        creationDate: formatDate(getValue('Creation Date')),
        expirationDate: formatDate(getValue('Registry Expiry Date')),
        registrar: getValue('Registrar'),
        registrarIanaId: getValue('Registrar IANA ID'),
        domainStatus: extractDomainStatuses(rawText),
        nameServers: extractNameServers(rawText),
        dnssec: getValue('DNSSEC')
      }
    })

    const toggleDetailedInfo = async () => {
      if (!detailedInfo.value && !isLoading.value) {
        isLoading.value = true
        try {
          const response = await apiClient.get('/api/whois/details', {
            params: {
              domain: savedDomain.value,
              whois_server: formattedData.value.registrarWhoisServer
            }
          })

          if (response.data.error) {
            throw new Error(response.data.error)
          }
          detailedInfo.value = response.data.detailed_info
          showDetailedInfo.value = true
        } catch (error) {
          console.error('Failed to fetch detailed WHOIS info:', error)
          alert('Failed to fetch detailed WHOIS information. Please try again later.')
        } finally {
          isLoading.value = false
        }
      } else {
        showDetailedInfo.value = !showDetailedInfo.value
      }
    }

    return {
      formattedData,
      detailedInfo,
      isLoading,
      showDetailedInfo,
      toggleDetailedInfo,
      isDomainAvailable
    }
  }
}
</script>

<style scoped>
.whois-result {
  margin-top: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 1.4;
}

.domain-available {
  margin-bottom: 20px;
}

.info-item {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
}

.label {
  color: #666;
  min-width: 220px;
  font-weight: 500;
}

.value {
  color: #333;
  flex: 1;
  word-break: break-word;
}

.status-list {
  flex: 1;
}

.status-item {
  margin-bottom: 8px;
}

.status-item a {
  color: #2196F3;
  text-decoration: none;
  transition: color 0.3s ease;
}

.status-item a:hover {
  color: #1976D2;
  text-decoration: underline;
}

.nameservers-list {
  flex: 1;
}

.nameservers-list div {
  margin-bottom: 5px;
  color: #333;
}

.more-info-section {
  margin-top: 20px;
  text-align: center;
}

.more-info-button {
  width: 100%;
  padding: 12px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-info-button:hover {
  background-color: #ebebeb;
}

.more-info-button:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  transition: transform 0.3s ease;
}

.arrow-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
}

.arrow-up {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #666;
}

.detailed-info {
  margin-top: 1px;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow-x: auto;
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.detailed-info pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
  text-align: left;
}

@media (max-width: 768px) {
  .whois-result {
    font-size: 14px;
  }

  .info-item {
    display: grid;
    grid-template-columns: minmax(140px, auto) 1fr;
    gap: 8px;
    margin-bottom: 12px;
    align-items: baseline;
  }

  .label {
    min-width: unset;
    text-align: right;
    color: #666;
    padding-right: 5px;
    font-weight: normal;
    font-size: 13px;
  }

  .value {
    text-align: left;
    word-break: break-word;
    font-size: 13px;
  }

  .status-item {
    margin-bottom: 8px;
    font-size: 13px;
  }

  .nameservers-list div {
    font-size: 13px;
    margin-bottom: 4px;
  }

  .more-info-button {
    padding: 10px;
  }

  .button-content {
    font-size: 13px;
  }

  .detailed-info {
    padding: 12px;
    font-size: 13px;
  }
}
</style>