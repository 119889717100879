import { createRouter, createWebHistory } from 'vue-router'
import WhoisLookup from '@/components/WhoisLookup.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: WhoisLookup,
        meta: {
            title: 'Domain Whois Lookup - WhoisJet.com',
            description:
                'Use WhoisJet.com to perform free Whois lookups for traditional domains, Web3 domains (.eth) and specialized TLDs (.ai, .xyz, .si). Check domain availability and find detailed registrant information quickly and accurately.'
        }
    },
    {
        path: '/whois/:domain([a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+)',
        name: 'DomainLookup',
        component: WhoisLookup,
        props: true,
        meta: {
            title: (route) => `${route.params.domain}`,
            description: (route) => `View WHOIS information for ${route.params.domain}. Check domain registration details, nameservers, and registrar information at WhoisJet.com.`
        }
    },
    {
        path: '/privacy',
        name: 'PrivacyPage',
        component: () => import('@/components/PrivacyPage.vue'),
        meta: {
            title: 'Privacy Policy | WhoisJet.com',
            description: 'WhoisJet.com Privacy Policy - Learn about how we protect your privacy and handle your data.'
        }
    },
    {
        path: '/:pathMatch(.*)*', // 404 page should be last
        name: 'NotFound',
        component: () => import('@/components/NotFound.vue'),
        meta: {
            title: '404 - Page Not Found | WhoisJet.com',
            description: 'The page you are looking for does not exist. Return to the WhoisJet.com homepage.'
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    let pageTitle = 'WhoisJet.com - Free Whois Lookup Tool';
    let description = 'WhoisJet.com provides free and accurate Whois lookup services for any domain name.';

    if (typeof to.meta.title === 'function') {
        pageTitle = to.meta.title(to);
    } else if (to.meta.title) {
        pageTitle = to.meta.title;
    }

    if (typeof to.meta.description === 'function') {
        description = to.meta.description(to);
    } else if (to.meta.description) {
        description = to.meta.description;
    }

    document.title = pageTitle;

    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute('content', description);
    } else {
        metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = description;
        document.head.appendChild(metaDescription);
    }

    next();
});

export default router