<template>
  <div class="related-links">
    <h2>Quick Links</h2>
    <div v-if="isLoading" class="loading">
      <el-skeleton :rows="5" animated />
    </div>
    <div v-else-if="currentDomain" class="links-content">
      <ul class="links-list">
        <li>
          <a :href="expiredDomainsUrl" target="_blank" rel="noopener">
            ExpiredDomains
          </a>
        </li>
        <li>
          <a :href="dotdbUrl" target="_blank" rel="noopener">
            DotDB
          </a>
        </li>
        <li>
          <a :href="godaddyUrl" target="_blank" rel="noopener">
            GoDaddy
          </a>
        </li>
        <li>
          <a :href="GDAuctions" target="_blank" rel="noopener">
            GD Auctions
          </a>
        </li>
        <li>
          <a :href="godaddyAppraisalUrl" target="_blank" rel="noopener">
            GD Appraisal
          </a>
        </li>
        <li>
          <a :href="sedoUrl" target="_blank" rel="noopener">
            Sedo
          </a>
        </li>
        <li>
          <a :href="googleSearchUrl" target="_blank" rel="noopener">
            Google Search
          </a>
        </li>
        <li>
          <a :href="googleTrendsUrl" target="_blank" rel="noopener">
            Google Trends
          </a>
        </li>
        <li>
          <a :href="InternetArchiveUrl" target="_blank" rel="noopener">
            Internet Archive
          </a>
        </li>
        <li>
          <a :href="benmiUrl" target="_blank" rel="noopener">
            Benmi
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  currentDomain: {
    type: String,
    default: ''
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const getDomainPrefix = computed(() => {
  // Split domain by dot and get the first part
  return props.currentDomain.split('.')[0];
});

const googleSearchUrl = computed(() => {
  return `https://www.google.com/search?q=${encodeURIComponent(getDomainPrefix.value)}`;
});

const googleTrendsUrl = computed(() => {
  return `https://trends.google.com/trends/explore?q=${encodeURIComponent(getDomainPrefix.value)}`;
});

const expiredDomainsUrl = computed(() => {
  return `https://member.expireddomains.net/domain-name-search/?q=${encodeURIComponent(getDomainPrefix.value)}&searchinit=1`;
});

const godaddyUrl = computed(() => {
  return `https://www.godaddy.com/domainsearch/find?domainToCheck=${encodeURIComponent(props.currentDomain)}`;
});

const GDAuctions = computed(() => {
  return `https://auctions.godaddy.com/beta?query=${encodeURIComponent(getDomainPrefix.value)}`;
});

const godaddyAppraisalUrl = computed(() => {
  return `https://www.godaddy.com/domain-value-appraisal/appraisal/?domainToCheck=${encodeURIComponent(props.currentDomain)}`;
});

const sedoUrl = computed(() => {
  return `https://sedo.com/search/?keyword=${encodeURIComponent(props.currentDomain)}&campaignId=56375`;
});

const dotdbUrl = computed(() => {
  return `https://dotdb.com/search?keyword=${encodeURIComponent(getDomainPrefix.value)}&position=any`;
});

const InternetArchiveUrl = computed(() => {
  const currentYear = new Date().getFullYear();
  return `https://web.archive.org/web/${currentYear}0000000000*/${encodeURIComponent(props.currentDomain)}`;
});

const benmiUrl = computed(() => {
  return `https://www.benmi.com/AuctionP.html?pp=30&q=${encodeURIComponent(getDomainPrefix.value)}&w=4&pw=0&c1=1`;
});

</script>

<style scoped>
.related-links {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex: 0.5; /* Allow it to take equal space */

}

.related-links h2 {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 16px;
}

.links-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.links-list li {
  margin-bottom: 12px;
}

.links-list li:last-child {
  margin-bottom: 0;
}

.links-list a {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 6px;
  color: #606266;
  text-decoration: none;
  transition: all 0.3s ease;
}

.links-list a:hover {
  background-color: #ebeef5;
  color: #409eff;
}

.links-list i {
  margin-right: 10px;
  width: 16px;
  text-align: center;
}

.loading {
  padding: 10px;
}

@media (max-width: 768px) {
  .related-links {
    margin-top: 16px;
  }
}
</style>