<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-links">
        <span class="copyright">© {{ currentYear }} WhoisJet.com</span>
        <router-link to="/privacy" class="privacy-link">Privacy Policy</router-link>
        <a href="https://api.whoisjet.com/sitemap.xml" target="_blank" class="privacy-link">Sitemap</a>
      </div>
      <a href="https://x.com/A6dyDomainer" target="_blank" class="social-link">
        <svg class="x-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
          <path fill="currentColor" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
        </svg>
      </a>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue'

const currentYear = ref(new Date().getFullYear())
</script>

<style scoped>
.footer {
  background-color: white;
  padding: 20px 0;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
}

.footer-content {
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.copyright {
  color: #666;
  font-size: 14px;
}

.privacy-link {
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.privacy-link:hover {
  text-decoration: underline;
}

.social-link {
  color: #666;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #1da1f2;
}

.x-icon {
  vertical-align: middle;
}

@media (max-width: 768px) {
  .footer-content {
    padding: 0 10px;
    font-size: 12px;
  }

  .footer-links {
    gap: 10px;
  }
}
</style>