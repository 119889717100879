<!-- src/components/SideBar.vue -->

<!-- src/components/SideBar.vue -->
<template>
  <div class="sidebar">
    <h2 v-html="getAverageYearTitle"></h2>
    <!-- Rest of the template remains exactly the same -->
    <div v-if="isLoading" class="loading">
      <el-skeleton :rows="8" animated />
    </div>
    <ul v-else-if="currentDomain || relatedDomainsResults.length > 0">
      <!-- Current domain item -->
      <li v-if="currentDomain">
        <div class="domain-item">
          <div class="domain-info">
            <div class="name-with-year">
              <a
                  :href="getDomainNameLink(currentDomain, currentDomainStatus)"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="domain-link"
              >
                <span class="domain-name">{{ currentDomain }}</span>
              </a>
              <span v-if="currentDomainCreationYear" class="year-tag">{{ currentDomainCreationYear }}</span>
              <span v-if="currentDomainSaleInfo?.is_for_sale" class="sedo-tag">
                <a :href="currentDomainSaleInfo.listing_url" target="_blank" rel="noopener noreferrer">
                  Sedo
                </a>
              </span>
            </div>
          </div>
          <a
              :href="currentDomainStatus === 'available' ? `https://www.godaddy.com/domainsearch/find?domainToCheck=${currentDomain}` : `/whois/${currentDomain}`"
              :target="currentDomainStatus === 'available' ? '_blank' : '_self'"
              :rel="currentDomainStatus === 'available' ? 'noopener noreferrer' : ''"
              class="domain-status-link"
          >
            <span :class="['domain-status', currentDomainStatus]">
              {{ getDomainStatusText(currentDomainStatus) }}
            </span>
          </a>
        </div>
      </li>
      <!-- Related domains -->
      <li v-for="domain in relatedDomainsResults" :key="domain.name">
        <div class="domain-item">
          <div class="domain-info">
            <div class="name-with-year">
              <a
                  :href="getDomainNameLink(domain.name, domain.status)"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="domain-link"
              >
                <span class="domain-name">{{ domain.name }}</span>
              </a>
              <span v-if="domain.creationYear" class="year-tag">{{ domain.creationYear }}</span>
              <span v-if="domain.saleInfo?.is_for_sale" class="sedo-tag">
                <a :href="domain.saleInfo.listing_url" target="_blank" rel="noopener noreferrer">
                  Sedo
                </a>
              </span>
            </div>
          </div>
          <a
              :href="getDomainLink(domain)"
              :target="domain.status === 'available' ? '_blank' : '_self'"
              :rel="domain.status === 'available' ? 'noopener noreferrer' : ''"
              class="domain-status-link"
          >
            <span v-if="domain.isChecking" class="domain-status checking">
              Checking...
            </span>
            <span v-else :class="['domain-status', domain.status]">
              {{ getDomainStatusText(domain.status) }}
            </span>
          </a>
        </div>
      </li>
    </ul>

    <!-- TLD Info Component -->
    <TldInfo :domain="currentDomain" />
  </div>
</template>

<script setup>
import { ref, watch, defineProps, computed } from 'vue'
import { useRoute } from 'vue-router'
import apiClient from '@/services/api'
import { ElSkeleton } from 'element-plus'
import TldInfo from './TldInfo.vue'

// Cache utilities
const CACHE_DURATION = 60 * 60 * 1000

const getCacheKey = (type, domain) => `domain_cache_${type}_${domain}`

const getFromCache = (type, domain) => {
  const key = getCacheKey(type, domain)
  const cached = localStorage.getItem(key)
  if (cached) {
    const { data, timestamp } = JSON.parse(cached)
    if (Date.now() - timestamp < CACHE_DURATION) {
      return data
    }
    localStorage.removeItem(key) // Remove expired cache
  }
  return null
}

const setToCache = (type, domain, data) => {
  const key = getCacheKey(type, domain)
  localStorage.setItem(key, JSON.stringify({
    data,
    timestamp: Date.now()
  }))
}

const props = defineProps({
  currentDomain: {
    type: String,
    default: ''
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  mainQueryResult: {
    type: Object,
    default: () => null
  }
})

const route = useRoute()
const relatedDomainsResults = ref([])
const isCheckingDomains = ref(false)
const currentDomainStatus = ref('unknown')
const currentDomainCreationYear = ref(null)
const currentDomainSaleInfo = ref(null)

const DISPLAY_TLD_LIMIT = 30
const tldCount = ref(null)
const displayedTlds = ref([])
const allRegisteredTlds = ref([])

const tldExtensions = [
  'com',
  'net',
  'org',
  'cn',
  'co',
  'io',
  'xyz'
]

const getDomainHomepage = (domain) => {
  return `http://${domain}`
}

const getDomainStatusText = (status) => {
  switch(status) {
    case 'available':
      return 'Available'
    case 'registered':
      return 'Whois'
    case 'error':
      return 'Error'
    default:
      return 'Unknown'
  }
}

const getDomainLink = (domain) => {
  if (domain.status === 'available') {
    return `https://www.godaddy.com/domainsearch/find?domainToCheck=${domain.name}`
  } else {
    return `/whois/${domain.name}`
  }
}

const getDomainNameLink = (domain, status) => {
  if (status === 'available') {
    return `https://www.godaddy.com/domainsearch/find?domainToCheck=${domain}`
  } else {
    return getDomainHomepage(domain)
  }
}

const checkDomainSale = async (domain) => {
  const cached = getFromCache('sale', domain)
  if (cached) return cached

  try {
    const response = await apiClient.get('/api/domain-sale', {
      params: {
        domain,
        platform: 'sedo'
      }
    })
    const saleInfo = response.data.sale_info
    setToCache('sale', domain, saleInfo)
    return saleInfo
  } catch (error) {
    console.error(`Error checking domain sale for ${domain}:`, error)
    return null
  }
}

const extractCreationYear = (whoisData) => {
  if (!whoisData || !whoisData.raw_data || !whoisData.raw_data.text) {
    return null
  }

  const text = whoisData.raw_data.text

  const patterns = [
    /Creation Date: (\d{4})-\d{2}-\d{2}/,
    /Registration Time: (\d{4})-\d{2}-\d{2}/,
    /Registration Date: (\d{4})-\d{2}-\d{2}/,
    /Created: (\d{4})-\d{2}-\d{2}/,
    /Created Date: (\d{4})-\d{2}-\d{2}/
  ]

  for (const pattern of patterns) {
    const match = text.match(pattern)
    if (match && match[1]) {
      return match[1]
    }
  }

  return null
}

const getAverageYearTitle = computed(() => {
  if (isCheckingDomains.value) {
    return 'Related Domains'
  }
  const years = []
  if (currentDomainCreationYear.value) {
    years.push(parseInt(currentDomainCreationYear.value))
  }
  relatedDomainsResults.value.forEach(domain => {
    if (domain.creationYear) {
      years.push(parseInt(domain.creationYear))
    }
  })
  if (years.length === 0) {
    return 'Related Domains'
  }
  const average = Math.round(years.reduce((a, b) => a + b, 0) / years.length)
  return `Avg Reg: <span class="average-number">${average}</span>`
})

const checkSingleDomain = async (domain) => {
  const cached = getFromCache('whois', domain)
  if (cached) return cached

  try {
    const response = await apiClient.get('/api/whois', {
      params: {domain}
    })

    const creationYear = extractCreationYear(response.data)
    let saleInfo = null

    if (response.data.status === 'registered') {
      saleInfo = await checkDomainSale(domain)
    }

    const result = {
      name: domain,
      status: response.data.status,
      creationYear: creationYear,
      saleInfo: saleInfo
    }

    setToCache('whois', domain, result)
    return result
  } catch (error) {
    console.error(`Error checking domain ${domain}:`, error)
    return {
      name: domain,
      status: 'error',
      creationYear: null,
      saleInfo: null
    }
  }
}

const updateRelatedDomains = async (baseDomain) => {
  if (!baseDomain) {
    clearResults()
    return
  }

  isCheckingDomains.value = true

  const [domainPrefix, ...tldParts] = baseDomain.split('.')
  const currentTld = tldParts.join('.')
  const filteredExtensions = tldExtensions.filter(tld => tld !== currentTld)

  relatedDomainsResults.value = filteredExtensions.map(tld => ({
    name: `${domainPrefix}.${tld}`,
    status: 'unknown',
    isChecking: true,
    creationYear: null,
    saleInfo: null
  }))

  try {
    const checkPromises = relatedDomainsResults.value.map(domain =>
        checkSingleDomain(domain.name)
    )

    const tldCountPromise = fetchTldCount(domainPrefix)

    const [tldCountResult, ...results] = await Promise.all([tldCountPromise, ...checkPromises])

    if (tldCountResult) {
      tldCount.value = tldCountResult
      displayedTlds.value = tldCountResult.registered_tlds.slice(0, DISPLAY_TLD_LIMIT)
      allRegisteredTlds.value = tldCountResult.registered_tlds
    }

    relatedDomainsResults.value = results.map(result => ({
      ...result,
      isChecking: false
    }))
  } catch (error) {
    console.error('Error checking domains:', error)
  } finally {
    isCheckingDomains.value = false
  }
}

const clearResults = () => {
  relatedDomainsResults.value = []
  isCheckingDomains.value = false
  currentDomainStatus.value = 'unknown'
  currentDomainCreationYear.value = null
  currentDomainSaleInfo.value = null
  tldCount.value = null
  displayedTlds.value = []
  allRegisteredTlds.value = []
}

const fetchTldCount = async (prefix) => {
  const cached = getFromCache('tld', prefix)
  if (cached) return cached

  try {
    const response = await apiClient.get('/api/tlds/count', {
      params: { domain: prefix }
    })
    if (response.data.status === 'success') {
      setToCache('tld', prefix, response.data)
      return response.data
    } else {
      console.error('Failed to fetch TLD count:', response.data.error)
      return null
    }
  } catch (error) {
    console.error(`Error fetching TLD count for ${prefix}:`, error)
    return null
  }
}

watch(() => props.mainQueryResult, async (newResult) => {
  if (newResult) {
    currentDomainStatus.value = newResult.status
    currentDomainCreationYear.value = extractCreationYear(newResult)
    if (newResult.status === 'registered' && props.currentDomain) {
      currentDomainSaleInfo.value = await checkDomainSale(props.currentDomain)
    }
  } else {
    currentDomainStatus.value = 'unknown'
    currentDomainCreationYear.value = null
    currentDomainSaleInfo.value = null
  }
})

watch(() => props.currentDomain, (newDomain) => {
  if (newDomain) {
    updateRelatedDomains(newDomain)
  } else {
    clearResults()
  }
}, { immediate: true })

watch(() => route.path, (newPath) => {
  if (newPath === '/') {
    clearResults()
  }
})
</script>

<style scoped>
.sidebar {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.sidebar h2 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #2c3e50;
}

.loading {
  padding: 10px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.domain-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.domain-info {
  display: flex;
  flex-direction: column;
}

.name-with-year {
  display: flex;
  align-items: center;
  gap: 8px;
}

.domain-name {
  color: #2c3e50;
  font-family: monospace;
}

.domain-link {
  text-decoration: none;
  color: inherit;
}

.domain-link:hover {
  color: inherit;
  text-decoration: none;
}

.year-tag {
  background-color: #909399;
  color: white;
  font-size: 11px;
  padding: 1px 6px;
  border-radius: 10px;
  display: inline-block;
}

.sedo-tag {
  background-color: rgb(0, 96, 169);
  padding: 1px 6px;
  border-radius: 10px;
  font-size: 11px;
}

.sedo-tag a {
  color: white;
  text-decoration: none;
}

.domain-status-link {
  text-decoration: none;
}

.domain-status {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.domain-status.available {
  background-color: #67c23a;
  color: white;
}

.domain-status.registered {
  background-color: #f56c6c;
  color: white;
}

.domain-status.error {
  background-color: #909399;
  color: white;
}

.domain-status.checking {
  background-color: #909399;
  color: white;
}

h2 :deep(.average-number) {
  color: #1e850d;
  font-weight: bold;
}

@media (max-width: 768px) {
  .sidebar {
    width: auto;
    margin-top: 20px;
  }
}
</style>