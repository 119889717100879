//src/services/api.js

import axios from 'axios'
import { getBaseURL } from '@/config'

// 创建 axios 实例
const apiClient = axios.create({
    baseURL: getBaseURL()
})

// 可以在这里添加拦截器
apiClient.interceptors.request.use(
    config => {
        // 请求拦截
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

apiClient.interceptors.response.use(
    response => {
        // 响应拦截
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

export default apiClient