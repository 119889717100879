<template>
  <div class="container">
    <header class="header">
      <div class="header-content">
        <div class="header-left">
          <div class="home-link" @click="goHome">
            <div class="logo">
              <img src="@/assets/logo.png" alt="Logo" class="logo-img">
            </div>
            <div class="title-group">
              <h1>WhoisJet.com Whois Lookup</h1>
              <p class="tagline">—— Powerful Tool For Domainers</p>
            </div>
          </div>
        </div>
        <div class="header-right">
          <a href="https://chromewebstore.google.com/detail/domain-whois-lookup-whois/chchdokbcbocmdnknopdgemijdfpgamf"
             target="_blank"
             class="extension-button">
            <img src="@/assets/chrome-logo.svg" alt="Chrome" class="chrome-icon">
            <span>Get Chrome Extension</span>
          </a>
        </div>
      </div>
    </header>

    <main class="main-content">
      <router-view></router-view>
    </main>

    <AppFooter />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import AppFooter from '@/components/AppFooter.vue'

const router = useRouter()

const goHome = () => {
  router.push('/')
}
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  top: 0;
  z-index: 100;
}

.header-content {
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  flex: 1;
}

.home-link {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.title-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.logo {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

h1 {
  font-size: 28px;
  margin: 0;
  color: #2c3e50;
  font-weight: 600;
}

.tagline {
  font-size: 14px;
  color: #666;
  margin: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 8px;
}

.header-right {
  display: flex;
  align-items: center;
}

.extension-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f8f9fa;
  color: #2c3e50;
  text-decoration: none;
  border-radius: 6px;
  font-size: 14px;
  border: 1px solid #e0e3e7;
  transition: all 0.3s ease;
}

.extension-button:hover {
  background-color: #e9ecef;
  border-color: #ced4da;
}

.chrome-icon {
  width: 18px;
  height: 18px;
}

.main-content {
  padding: 40px 60px;
  flex: 1;
}

@media (max-width: 768px) {
  .header {
    padding: 15px 0;
  }

  .header-content {
    padding: 0 15px;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  h1 {
    font-size: 20px;
  }

  .tagline {
    font-size: 12px;
    gap: 6px;
  }

  .main-content {
    margin: 20px 0;
    padding: 0 15px;
  }

  .header-right {
    display: none;
  }
}
</style>